<template>
    <div class='statement'>
        <a-tabs @change="change" v-model:activeKey="tabIndex">
            <a-tab-pane 
                :key="tabIndex" 
                :tab="tab.label" 
                v-for="(tab, tabIndex) in tabs">
                <p class="refresh_time">
                    最近刷新时间： {{time}}
                    <a-button 
                        type="primary" 
                        :loading="loading" 
                        @click="loadData" 
                        class="refresh_time_button">{{loading ? '刷新中' : '立即刷新'}}</a-button>
                </p>
                 <ul class="statement_box">
                    <li 
                        v-for="(item, itemIndex) in showInfo"
                        class="statement_box_info" 
                        :key="itemIndex">
                            <p class="info_title">{{item.title}}</p>
                            <p :class="itemIndex == 0 ? 'black' : itemIndex == 1 ? 'blue' : 'gray'" class="info_value">{{item.value}}</p>
                            <div class="info_tip">
                                <p 
                                    v-for="(tip, tipIndex) in item.tips" 
                                    :key="tipIndex">
                                    <span>{{tip.label}}</span>
                                    <span class="red">{{tip.value}}</span>
                                    <span>{{tip.unit}}</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import {computed, reactive, toRefs } from 'vue'
import { getStatement, isValid } from '@/utils/api'
import { formatTime } from '@/utils/util'
export default {
    name: '',
    setup() {
        const state = reactive({
            tabs: [
                    {label: '店铺统计', value: 1}, 
                    {label: '彩民统计', value: 2},
                    {label: '销量统计', value: 3},
                    {label: '提现统计', value: 4},
                    {label: '兑奖统计', value: 5},
                    {label: '佣金统计', value: 6},
                ],
            tabIndex: 0,
            refreshTime: '',
            time: '',
            list: [],
            loading: false,
            statementInfo: {
                1: [
                    {
                        title: '总店铺数(家)',
                        value: 0,
                        prop: 'shopTotal',
                        tips: [
                            {
                                label: '已投放',
                                value: 0,
                                prop: 'successShopCount'
                            },
                            {
                                label: '暂未投放',
                                value: 0,
                                prop: 'awaitShopCount'
                            }
                        ]
                    },
                    {
                        title: '今日新增店铺数(家)',
                        value: 0,
                        prop: 'todayShopCount',
                        tips: [
                            {
                                label: '继续努力哦',
                                // prop: 'ydayShopCount',
                                // value: 0
                            }
                        ]
                    },
                    {
                        title: '昨日新增店铺数(家)',
                        value: 0,
                        prop: 'ydayShopCount',
                        tips: [
                            {label: '还不错哦，继续努力'}
                        ]
                    }
                ],
                2: [
                    {
                        title: '历史总人数(人)',
                        value: 0,
                        prop: 'userTotal',
                        tips: [
                            {label: '离暴富越来越近越努力'}
                        ]
                    },
                    {
                        title: '今日新增(人)',
                        value: 0,
                        prop: 'todayUserCount',
                        tips: [
                            {label: '继续努力哦'}
                        ]
                    },
                    {
                        title: '昨日新增(人)',
                        value: 0,
                        prop: 'ydayUserCount',
                        tips: [
                            {label: '还不错哦, 继续努力'}
                        ]
                    }
                ],
                3: [
                    {
                        title: '总交易额(元)',
                        value: 0,
                        prop: 'saleTotal',
                        tips: [
                            {
                                label: '总交易笔数',
                                value: 0,
                                prop: 'saleCount',
                                unit: '笔'
                            }
                        ]
                    },
                    {
                        title: '今日销量(元)',
                        value: 0,
                        prop: 'todaySaleTotal',
                        tips: [
                            {
                                label: '成交笔数',
                                value: 0,
                                prop: 'todaySaleCount',
                                unit: '笔'
                            }
                        ]
                    },
                    {
                        title: '昨日销量(元)',
                        value: 0,
                        prop: 'ydaySaleTotal',
                        tips: [
                            {
                                label: '成交笔数',
                                value: 0,
                                prop: 'ydaySaleCount',
                                unit: '笔'
                            }
                        ]
                    }
                ],
                4: [
                    {
                        title: '提现总金额(元)',
                        value: 0,
                        prop: 'withDrawTotal',
                        tips: [
                            {
                                label: '提现总笔数',
                                value: 0,
                                prop: 'withDrawCount',
                                unit: '笔'
                            }
                        ]
                    },
                    {
                        title: '今日提现金额(元)',
                        value: 0,
                        prop: 'todayWithDraw',
                        tips: [
                            {
                                label: '提现笔数',
                                value: 0,
                                prop: 'todayWithDrawCount',
                                unit: '笔'
                            }
                        ]
                    },
                    {
                        title: '昨日提现金额(元)',
                        value: 0,
                        prop: 'ydayWithDrawTotal',
                        tips: [
                            {
                                label: '提现笔数',
                                value: 0,
                                prop: 'ydayWithDrawCount',
                                unit: '笔'
                            }
                        ]
                    }
                ],
                5: [
                    {
                        title: '兑奖总金额(元)',
                        value: 0,
                        prop: 'ticketPrizeTotal',
                        tips: [
                            {
                                label: '兑奖总笔数',
                                value: 0,
                                prop: 'ticketPrizeCount',
                                unit: '笔'
                            },
                        ]
                    },
                    {
                        title: '今日兑奖金额(元)',
                        value: 0,
                        prop: 'todayTicketPrize',
                        tips: [
                            {
                                label: '兑奖笔数',
                                value: 0,
                                prop: 'todayTicketPrizeCount',
                                unit: '笔'
                            }
                        ]
                    },
                    {
                        title: '昨日兑奖金额(元)',
                        value: 0,
                        prop: 'ydayTicketPrizeTotal',
                        tips: [
                            {
                                label: '兑奖笔数',
                                value: 0,
                                prop: 'ydayTicketPrizeCount',
                                unit: '笔'
                            }
                        ]
                    }
                ],
                6: [
                    {
                        title: '总佣金(元)',
                        value: 0,
                        prop: 'incomeTotal',
                        tips: [
                            {
                                label: '代理总佣金',
                                value: 0,
                                prop: 'agentIncomeTotal',
                                unit: '元'
                            },
                            {
                                label: '商家总佣金',
                                value: 0,
                                prop: 'shopIncomeTotal',
                                unit: '元'
                            }
                        ]
                    },
                    {
                        title: '今日佣金预估(元)',
                        value: 0,
                        prop: 'todayIncomeTotal',
                        tips: [
                            {
                                label: '以次日实际结算为准',
                                // value: 0,
                                // prop: 'todayAgentIncome',
                                // unit: '元'
                            },
                        ]
                    },
                    {
                        title: '昨日佣金(元)',
                        value: 0,
                        prop: 'ydayIncomeTotal',
                        tips: [
                            {
                                label: '昨日代理佣金',
                                value: 0,
                                prop: 'ydayAgentIncome',
                                unit: '元'
                            },
                            {
                                label: '昨日商家佣金',
                                value: 0,
                                prop: 'ydayShopIncome',
                                unit: '元'
                            }
                        ]
                    }
                ]
            }
        })
        const showInfo = computed(() => {
            return state.statementInfo[state.tabs[state.tabIndex].value]
        })
        async function loadData() {
            try {
                const tab = state.tabs[state.tabIndex]
                const currentItem = state.statementInfo[tab.value]
                state.loading = true
                const result = await getStatement(tab.value)
                if (isValid(result)) {
                    state.time = formatTime(new Date())
                    const data = result.data
                    currentItem.forEach((item) => {
                        item.value = data[item.prop] || 0
                        item.tips && item.tips.forEach((ele => {
                            if (ele.prop) {
                            ele.value = data[ele.prop]
                        }
                        }))
                    })
                }
                state.loading = false
            } catch(e) {
                console.error(e)
            }
        }
        function change(e) {
            state.tabIndex = e 
            loadData()
        }
        loadData()
        return {
            ...toRefs(state),
            showInfo,
            change,
            loadData
        }
    }
}
</script>
<style lang="scss">
.ant-tabs-nav-scroll {
    text-align: left;
}
.black {
    color: #000;
}
.blue {
    color: #276DFF;
}
.gray {
    color: #626262;
}
.red {
    color: #f00;
}
.refresh_time {
    color: #666;
    text-align: left;
    padding: 20px 0 8px 0;
    &_button {
        display: inline-block;
        color: #fff;
        background: #276DFF;
        padding: 5px 15px;
        margin-left: 20px;
    }
}
.statement_box {
    display: flex;
    margin-right: 20px;
    &_info {
        background: #F7F7F7;
        border: solid #F7F7F7 1px;
        margin-right: 20px;
        padding: 20px;
        width: 180px;
        border-radius: 5px;
        &:hover {
            border: solid 1px  #276DFF;
            background:#F3F6FF;
        }
        p {
            margin: 0;
        }
        .info {
            &_title {
                font-size: 14px;
                color: #000;
            }
            &_value {
                font-size: 32px;
                padding: 10px 0;
            }
            &_tip {
                display: flex;
                text-align: center;
                flex-wrap: wrap;
                justify-content: center;
                font-size: 12px;
                p {
                    margin: 0 5px;
                }
            }
        }
    }
}
</style>
